.ccButton {
    background-color: var(--bgopposite1);
    color:  var(--textopposite1);
    border: none;
    cursor: pointer;
    font-size: 1.5vw;
    border-radius: 1vw;
    padding: 0.5vw 1.25vw;
    margin-left: 1vw;
    transform: rotate(-90deg);
  }
  
  .cclogorotate {
    display: inline-block;
    font-weight: 700;
    min-height: 0; /* Safari fix */
    min-width: 0; /* Safari fix */
    flex-shrink: 1;
    white-space: nowrap;
    writing-mode: vertical-lr;
    cursor: none;
    transform: rotate(180deg);
    color:var(--textmain1);
    font-size: 10px;
    opacity: 0.8;

  }

  .LogoOnRestofThePages
  {
    position: fixed;
    left: -6.5%;
    top:40%;
  }

.EaseMenuHome:hover, .EaseMenu:hover
{
  background-color: var(--hovermain);

}

.EaseMenuHome:active, .EaseMenu:active
{
  background-color:var(--highlightgreen);
  border: none;
  color: var(--textongreen);
}


.AllMenusSidebar
{
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 50px;
  flex-shrink: 0;
  flex-grow: 0;
  left: 20px;
  top: 4vh;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4vh;
  z-index:992;
}

.SideBarOnIconizedMenu
{
  background-color: var(--bg-color);
  height: 100%;
}

.EaseMenu
{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: var(--bordermain);
  border-radius: 0.5vw;
  background-color: var(--bgmain2);
  padding: 0.5rem;
  cursor: pointer;
  width: 50px;
  height: 50px;
  min-height: 0; /* Safari fix */
  min-width: 0; /* Safari fix */
  flex-shrink: 1;
}


.EaseMenuHome
{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: var(--bordermain);
  border-radius: 0.5vw;
  background-color: var(--bgmain2);
  padding: 0.5rem;
  cursor: pointer;
  width: 50px;
  height: 50px;
  min-height: 0; /* Safari fix */
  min-width: 0; /* Safari fix */
  flex-shrink: 1;
}

.EaseMenu:active
{
  background-color: var(--highlightgreen);
}

.EaseMenuDiag
{
  position: fixed;
  left:30%;
  top:30%;
  max-height: 60%;
  overflow: auto;
  z-index:993;
  background-color: var(--bgopposite1);
  border-radius: 0.5vw;
  padding: 0.5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap:2vh;
  padding: 1vw;
}

.menu_search--bar
{
  background-color: var(--bg-color);
    overflow: hidden;
    border: none;
    border-radius: 0.5rem;
    padding: 0.25rem 1rem;
    color: var(--textmain1);
    width: 100%;
    font-size: min(16px, 1rem);
}
.menu_search--bar:focus
{
  outline: 2px solid #4cbf6a;

}
.menu_search--bar::placeholder
{
  color: var(--textmain2);
}
.menu_search--button {
  background-color: var(--bgopposite2);
  border: var(--borderopposite);
  border-radius: 0.5rem;
  height: auto;
  width: auto;
  padding: 0.5rem;
  cursor: pointer;

}
.menu_search--button svg
{
  fill: var(--bgmain2);
}
.menu_search--button:disabled svg
{
  fill: var(--bgmain);
}
.menu_search--button:not(:disabled):hover{
  background-color: var(--hoveropposite);
}
.menu_other {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  gap: 4%;
}

.menu_search--button:active{
  background-color: var(--highlightgreen);
  color: var(--textongreen);
}

.menu_other--logout {
  background-color: var(--bgopposite2);
  border: var(--borderopposite);
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  color: var(--textopposite1);
  font-size: 1rem;
  user-select: none;
  cursor: pointer;

}

.menu_other--logout:hover
{
  background-color: var(--hoveropposite);
}
.menu_other--logout:active{
  background-color: var(--highlightgreen);
  color: var(--textongreen);
}
.menu_search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  max-height: 45px;
  width: 100%;
  gap: 4%;
}

@media only screen and (orientation:portrait){
  .EaseMenu{
    padding: unset;
  }

}

